import { Carousel, Card, Button } from 'react-bootstrap';
import bailey from './image/bailey.png'; // import your product image here

const products = [
  { name: 'Wine Name 1', image: bailey },
  { name: 'Wine Name 2', image: bailey },
  { name: 'Wine Name 3', image: bailey },
  { name: 'Wine Name 4', image: bailey },
  { name: 'Wine Name 5', image: bailey },
  { name: 'Wine Name 6', image: bailey },
];

function ProductCarousel() {
  return (
    <Carousel>
      {products.map((product, index) => (
        <Carousel.Item key={index}>
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={product.image} />
            <Card.Body>
              <Card.Title>{product.name}</Card.Title>
              <Button variant="primary">Add to Cart</Button>
            </Card.Body>
          </Card>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
export default ProductCarousel;
