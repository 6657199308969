import React, { Component, useContext } from 'react'
import { Container, Col, Row, Button, Card } from 'react-bootstrap'
import {FcGoogle} from 'react-icons/fc'
import {BsFacebook} from 'react-icons/bs'
import {BsApple} from 'react-icons/bs'
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom'

const Login = () => {
    let {loginUser, logoutUser} = useContext(AuthContext)
    

  
    return (
        <div className="auth-wrapper d-flex justify-content-center">
        <Container>
        <form className='pt-3' onSubmit={loginUser}>
        
        <Row>
            <Col><h3>Log in</h3></Col>
        </Row>
        

        <Row>
            <Col>
            <div className="mb-3">
          <p>Email</p>
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email"
          />
        </div>
            </Col>
        </Row>

        <Row>
            <Col>
            <div className="mb-3">
          <p>Password</p>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="********"
          />
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className="mb-3">
          <div className="custom-control custom-checkbox">

            <p>Trouble logging in? Reset password</p>
          </div>
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Log in
          </button>
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div>
  <h6 className="forgot-password text-center" style={{textAlign: 'center'}}>
    Don't have an account?
    <Link to="/signup" style={{marginLeft: '5px', color:'#1A0F29'}}>Signup</Link>
  </h6>
</div>

            
       
            </Col>
        </Row>
        
      </form>

        </Container>
      </div>
    )
  }
  export default Login;
