import { Route, Redirect, Navigate } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'

const PublicRoute = ({children, ...rest}) => {
    let{user} = useContext(AuthContext)

                                                                                                                                                                                       
    return children;
}

export default PublicRoute;