import jwt_decode from "jwt-decode"
const Home =()=>{
    console.log(localStorage.getItem('authTokens'))
    {console.log(jwt_decode(localStorage.getItem('authTokens')))}
    

    return(
        
        <div>
            <p>Logged In</p>
        </div>
    )
} 
export default Home;