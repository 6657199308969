import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function BookingForm() {
  const [bookingPeriod, setBookingPeriod] = useState('daily');
  const [bookingDates, setBookingDates] = useState([]);

  const handleBookingPeriodChange = (event) => {
    setBookingPeriod(event.target.value);
  };

  const handleDateChange = (date) => {
    setBookingDates([...bookingDates, date]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Submit booking to backend system
  };

  const renderDatePicker = () => {
    switch (bookingPeriod) {
      case 'daily':
        return (
          <DatePicker
            selected={bookingDates[0]}
            onChange={(date) => setBookingDates([date])}
          />
        );
      case 'weekly':
        return (
          <>
            {[...Array(7)].map((_, index) => (
              <DatePicker
                key={index}
                selected={bookingDates[index]}
                onChange={(date) => {
                  const newBookingDates = [...bookingDates];
                  newBookingDates[index] = date;
                  setBookingDates(newBookingDates);
                }}
              />
            ))}
          </>
        );
      case 'monthly':
        return (
          <>
            {[...Array(31)].map((_, index) => (
              <DatePicker
                key={index}
                selected={bookingDates[index]}
                onChange={(date) => {
                  const newBookingDates = [...bookingDates];
                  newBookingDates[index] = date;
                  setBookingDates(newBookingDates);
                }}
              />
            ))}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Booking period:
        <select value={bookingPeriod} onChange={handleBookingPeriodChange}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </label>
      {renderDatePicker()}
      <button type="submit">Submit</button>
    </form>
  );
}
export default BookingForm;

