import logo from './logo.svg';
import React, { Component } from 'react';
import './App.css';
import WineStore from './components/home.component';
import MyCarousel from './components/carousel.component';
import Searchbar from './components/searchbar.component';
import NavBar from './components/search.component';
import BookingForm from './components/datepicker.component';
import ProductCarousel from './components/searchbar.component';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/home.component';
import SignUp from './components/signup.components';
import Login from './components/login.component';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

function App() {
  return (
    <div className="App">
      <Router>
        
        <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute> <Home/> </PrivateRoute>}/>
          <Route path="/show" element={<ProductCarousel/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<PublicRoute> <SignUp/> </PublicRoute>}/>
          

        </Routes>
        </AuthProvider>
       
       
      </Router>
      
      
      
      
      
    </div>
  );
}

export default App;
