import React, { Component, useContext } from 'react'
import { Container, Col, Row, Button, Card } from 'react-bootstrap'
import {FcGoogle} from 'react-icons/fc'
import {BsFacebook} from 'react-icons/bs'
import {BsApple} from 'react-icons/bs'
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom'
const SignUp =()=> {
  let {signupUser} = useContext(AuthContext)
  
    return (
        <div className="auth-wrapper d-flex justify-content-center">
        <Container>
        <form className='pt-3' onSubmit={signupUser}>
        
        <Row>
            <Col><h3>Sign up</h3></Col>
        </Row>
        <Row>
            <Col>
            <div className="mb-3">
          <p>First Name</p>
          <input
            type="text"
            name="firstName"
            className="form-control"
            placeholder="Enter First Name"
            required
          />
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className="mb-3">
          <p>Last Name</p>
          <input
            type="text"
            name="lastName"
            className="form-control"
            placeholder="Enter Last Name"
            required
          />
        </div>
            </Col>
        </Row>

        <Row>
            <Col>
            <div className="mb-3">
          <p>Phone No</p>
          <input
            type="text"
            name="number"
            className="form-control"
            placeholder="Enter No"
            required
          />
        </div>
            </Col>
        </Row>

        <Row>
            <Col>
            <div className="mb-3">
          <p>Email</p>
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email"
            required
          />
        </div>
            </Col>
        </Row>

        <Row>
            <Col>
            <div className="mb-3">
          <p>Password</p>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="********"
            required
          />
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className="mb-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              I agree to the terms and conditions
            </label>
          </div>
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Create account
          </button>
        </div>
            </Col>
        </Row>
        <Row>
            <Col>
            <div>
            <h6 className="forgot-password text-center" style={{textAlign: 'center'}}>
    Akready have an account?
    <Link to="/login" style={{marginLeft: '5px', color:'#1A0F29'}}>Log in</Link>
  </h6>
            </div>
            
       
            </Col>
        </Row>
        
      </form>
      <Row>
        <Col xs={12}>
        <h6>OR</h6>
        </Col>
        <Col xs={12}>
        <Card>
            <Card.Body>
                <div className='text-center'>
                <h5><FcGoogle size={35}/> Continue with google</h5>
                </div>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12}>
        <Card>
            <Card.Body>
                <div className='text-center'>
                <h5><BsFacebook size={35} color={'#1877F2'}/> Continue with Facebook</h5>
                </div>
            </Card.Body>
        </Card>
        </Col>
        <Col xs={12}>
        <Card>
            <Card.Body>
                <div className='text-center'>
                <h5><BsApple size={35}/> Continue with Apple</h5>
                </div>
            </Card.Body>
        </Card>
        </Col>
      </Row>
        </Container>
      </div>
    )
  }
export default SignUp
